import Vue from 'vue'
import Router from 'vue-router'
import * as dd from 'dingtalk-jsapi';
Vue.use(Router)

const router = new Router({
  // mode: 'history',
  routes: [{
      path: '/loginFirst',
      name: 'index',
      component: () => import('../view/login/login'),
      meta: {
        keepAlive: false,
        title: '登录'
      }
    },
    {
      path: '/',
      name: 'loginFirst',
      component: () => import('../view/login/loginFirst'),
      meta: {
        keepAlive: false,
        title: '登录'
      }
    },
    {
      path: '/loginCompany',
      name: 'loginCompany',
      component: () => import('../view/login/loginCompany'),
      meta: {
        keepAlive: false,
        title: '登录(企业)'
      }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('../view/dashboard/dashboard'),
      meta: {
        keepAlive: false,
        title: '首页'
      }
    },
    //心愿部分
    {
      path: '/tinyWish',
      name: 'tinyWish',
      component: () => import('../view/tinyWish/index.vue'),
      meta: {
        keepAlive: false,
        title: '微心愿'
      }
    },
    {
      path: '/toCheckList',
      name: 'toCheckList',
      component: () => import('../view/tinyWish/toCheckList.vue'),
      meta: {
        keepAlive: false,
        title: '微心愿审批'
      }
    },
    {
      path: '/uncheckedDetail/:id',
      name: 'uncheckedDetail',
      component: () => import('../view/tinyWish/uncheckedDetail.vue'),
      meta: {
        keepAlive: false,
        title: '心愿详情'
      }
    },
    {
      path: '/hadCheckedDetail/:id',
      name: 'hadCheckedDetail',
      component: () => import('../view/tinyWish/hadCheckedDetail.vue'),
      meta: {
        keepAlive: false,
        title: '心愿详情'
      }
    },
    {
      path: '/ungetDetail/:id',
      name: 'ungetDetail',
      component: () => import('../view/tinyWish/ungetDetail.vue'),
      meta: {
        keepAlive: false,
        title: '心愿详情'
      }
    },
    {
      path: '/hadGetDetail/:id',
      name: 'hadGetDetail',
      component: () => import('../view/tinyWish/hadGetDetail.vue'),
      meta: {
        keepAlive: false,
        title: '心愿详情'
      }
    },
    {
      path: '/finishWish/:id',
      name: 'finishWish',
      component: () => import('../view/tinyWish/finishWish.vue'),
      meta: {
        keepAlive: false,
        title: '心愿详情'
      }
    },
    {
      path: '/postMyWish',
      name: 'postMyWish',
      component: () => import('../view/tinyWish/postMyWish.vue'),
      meta: {
        keepAlive: false,
        title: '我的心愿'
      }
    },
    {
      path: '/mainPage',
      name: 'mainPage',
      component: () => import('../view/mainPage/MainPage'),
      meta: {
        keepAlive: false,
        title: '智慧党建'
      }
    },
    {
      path: '/mainPageAnder',
      name: 'mainPageAnder',
      component: () => import('../view/mainPageAnder/MainPageAnder'),
      meta: {
        keepAlive: false,
        title: '党建统领'
      }
    },
    {
      path: '/memberInfo',
      name: 'memberInfo',
      component: () => import('../view/memberInfo/index'),
      meta: {
        keepAlive: false,
        title: '党员信息'
      }
    },
    {
      path: '/organization',
      name: 'organization',
      component: () => import('../view/organization/index'),
      meta: {
        keepAlive: false,
        title: '党组织架构'
      }
    },
    {
      path: '/activityList',
      name: 'activityList',
      component: () => import('../view/activity/activityList'),
      meta: {
        keepAlive: false,
        title: '活动'
      }
    },
    {
      path: '/mine',
      name: 'mine',
      component: () => import('../view/mine/index'),
      meta: {
        keepAlive: false,
        title: '我的'
      }
    },
    {
      path: '/myScore',
      name: 'myScore',
      component: () => import('../view/myScore/index'),
      meta: {
        keepAlive: false,
        title: '我的积分'
      }
    },
    {
      path: '/scoreRule',
      name: 'scoreRule',
      component: () => import('../view/myScore/scoreRule'),
      meta: {
        keepAlive: false,
        title: '积分规则'
      }
    },
    {
      path: '/pairingScore',
      name: 'pairingScore',
      component: () => import('../view/myScore/pairingScore'),
      meta: {
        keepAlive: false,
        title: '结对党员积分'
      }
    },
    {
      path: '/activityDetail',
      name: 'activityDetail',
      component: () => import('../view/activity/activityDetail'),
      meta: {
        keepAlive: false,
        title: '活动详情'
      }
    },
    {
      path: '/activityComment',
      name: 'activityComment',
      component: () => import('../view/activity/activityComment'),
      meta: {
        keepAlive: false,
        title: '评论'
      }
    },
    {
      path: '/taskList',
      name: 'taskList',
      component: () => import('../view/task/taskList'),
      meta: {
        keepAlive: false,
        title: '任务'
      }
    },
    {
      path: '/taskDetail',
      name: 'taskDetail',
      component: () => import('../view/task/taskDetail'),
      meta: {
        keepAlive: false,
        title: '任务详情'
      }
    },
    {
      path: '/taskComment',
      name: 'taskComment',
      component: () => import('../view/task/taskComment'),
      meta: {
        keepAlive: false,
        title: '任务总结'
      }
    },
    {
      path: '/taskApproveList',
      name: 'taskApproveList',
      component: () => import('../view/taskApprove/taskApproveList'),
      meta: {
        keepAlive: false,
        title: '任务审批'
      }
    },
    {
      path: '/taskApproveDetail',
      name: 'taskApproveDetail',
      component: () => import('../view/taskApprove/taskApproveDetail'),
      meta: {
        keepAlive: false,
        title: '任务审批详情'
      }
    },
    {
      path: '/taskApproveSubmit',
      name: 'taskApproveSubmit',
      component: () => import('../view/taskApprove/taskApproveSubmit'),
      meta: {
        keepAlive: false,
        title: '审批'
      }
    },
    {
      path: '/situationFeedbackList',
      name: 'situationFeedbackList',
      component: () => import('../view/situationFeedback/situationFeedbackList'),
      meta: {
        keepAlive: false,
        title: '情况反馈'
      }
    },
    {
      path: '/situationFeedbackDetail',
      name: 'situationFeedbackDetail',
      component: () => import('../view/situationFeedback/situationFeedbackDetail'),
      meta: {
        keepAlive: false,
        title: '情况反馈详情'
      }
    },
    {
      path: '/situationFeedbackSubmit',
      name: 'situationFeedbackSubmit',
      component: () => import('../view/situationFeedback/situationFeedbackSubmit'),
      meta: {
        keepAlive: false,
        title: '回复'
      }
    },
    {
      path: '/sfDashBoardList',
      name: 'sfDashBoardList',
      component: () => import('../view/situationFeedback/SFDashboardList'),
      meta: {
        keepAlive: false,
        title: '情况反馈'
      }
    },
    {
      path: '/sfDashboardDetail',
      name: 'sfDashboardDetail',
      component: () => import('../view/situationFeedback/SFDashboardDetail'),
      meta: {
        keepAlive: false,
        title: '情况反馈详情'
      }
    },
    {
      path: '/sfDashboardIWantTo',
      name: 'sfDashboardIWantTo',
      component: () => import('../view/situationFeedback/SFDashboardIWantTo'),
      meta: {
        keepAlive: false,
        title: '我要反馈'
      }
    },
    {
      path: '/lifeConsultationList',
      name: 'lifeConsultationList',
      component: () => import('../view/lifeConsultation/lifeConsultationList'),
      meta: {
        keepAlive: false,
        title: '生活咨询'
      }
    },
    {
      path: '/lifeConsultationDetail',
      name: 'lifeConsultationDetail',
      component: () => import('../view/lifeConsultation/lifeConsultationDetail'),
      meta: {
        keepAlive: false,
        title: '生活咨询详情'
      }
    },
    {
      path: '/lifeConsultationWantTo',
      name: 'lifeConsultationWantTo',
      component: () => import('../view/lifeConsultation/lifeConsultationWantTo'),
      meta: {
        keepAlive: false,
        title: '我要咨询'
      }
    },
    {
      path: '/lifeConsultationAuditSubmit',
      name: 'lifeConsultationAuditSubmit',
      component: () => import('../view/lifeConsultation/lifeConsultationAuditSubmit'),
      meta: {
        keepAlive: false,
        title: '回复'
      }
    },
    {
      path: '/lifeConsultationAuditList',
      name: 'lifeConsultationAuditList',
      component: () => import('../view/lifeConsultation/lifeConsultationAuditList'),
      meta: {
        keepAlive: false,
        title: '生活咨询'
      }
    },
    {
      path: '/lifeConsultationAuditDetail',
      name: 'lifeConsultationAuditDetail',
      component: () => import('../view/lifeConsultation/lifeConsultationAuditDetail'),
      meta: {
        keepAlive: false,
        title: '生活咨询详情'
      }
    },
    {
      path: '/getWish',
      name: 'getWish',
      component: () => import('../view/tinyWish/getWish.vue'),
      meta: {
        keepAlive: false,
        title: '认领心愿'
      }
    },
    //心里话部分
    {
      path: '/heartWord',
      name: 'heartWord',
      component: () => import('../view/heartWord/index.vue'),
      meta: {
        keepAlive: false,
        title: '心里话'
      }
    },
    {
      path: '/writeLetter',
      name: 'writeLetter',
      component: () => import('../view/heartWord/writeLetter.vue'),
      meta: {
        keepAlive: false,
        title: '我要写信'
      }
    },
    {
      path: '/letterDetail/:id',
      name: 'letterDetail',
      component: () => import('../view/heartWord/letterDetail.vue'),
      meta: {
        keepAlive: false,
        title: '公开信详情'
      }
    },
    {
      path: '/toMeDetail/:id',
      name: 'toMeDetail',
      component: () => import('../view/heartWord/toMeDetail.vue'),
      meta: {
        keepAlive: false,
        title: '心里话详情'
      }
    },
    //结对帮扶
    {
      path: '/pairToHelp',
      name: 'pairToHelp',
      component: () => import('../view/pairToHelp/index.vue'),
      meta: {
        keepAlive: false,
        title: '结对帮扶'
      }
    },
    {
      path: '/infoDetail/:id',
      name: 'infoDetail',
      component: () => import('../view/pairToHelp/infoDetail.vue'),
      meta: {
        keepAlive: false,
        title: '党员信息'
      }
    },
    // 结对帮扶-s
    {
      path: '/pairSupport',
      name: 'pairSupport',
      component: () => import('../view/pairSupport/index.vue'),
      meta: {
        keepAlive: false,
        title: '结对帮扶'
      }
    },
    // 帮扶日志
    {
      path: '/logList',
      name: 'logList',
      component: () => import('../view/pairSupport/logList.vue'),
      meta: {
        keepAlive: false,
        title: '帮扶日志'
      }
    },
    // 日志编辑
    {
      path: '/logSave',
      name: 'logSave',
      component: () => import('../view/pairSupport/logSave.vue'),
      meta: {
        keepAlive: false,
        title: '日志编辑'
      }
    },
    // 日志详情
    {
      path: '/logDetail/:id',
      name: 'logDetail',
      component: () => import('../view/pairSupport/logDetail.vue'),
      meta: {
        keepAlive: false,
        title: '日志详情'
      }
    },
    //综合资讯
    {
      path: '/compreInfo',
      name: 'compreInfo',
      component: () => import('../view/compreInfo/index.vue'),
      meta: {
        keepAlive: false,
        title: '综合资讯'
      }
    },
    {
      path: '/compreInfoDetail',
      name: 'compreInfoDetail',
      component: () => import('../view/compreInfo/compreInfoDetail'),
      meta: {
        keepAlive: false,
        title: '党建风采'
      }
    },
    {
      path: '/sFScreenList',
      name: 'sFScreenList',
      component: () => import('../view/situationFeedbackScreen/SFScreenList'),
      meta: {
        keepAlive: false,
        title: '情况反馈'
      }
    },
    {
      path: '/sFScreenoDetail',
      name: 'sFScreenoDetail',
      component: () => import('../view/situationFeedbackScreen/SFScreenDetail'),
      meta: {
        keepAlive: false,
        title: '反馈详情'
      }
    },
    {
      path: '/sFScreenIWantTo',
      name: 'sFScreenIWantTo',
      component: () => import('../view/situationFeedbackScreen/SFScreenIWantTo'),
      meta: {
        keepAlive: false,
        title: '我要反馈'
      }
    },
    {
      path: '/getWishScreen',
      name: 'getWishScreen',
      component: () => import('../view/tinyWishScreen/getWishScreen'),
      meta: {
        keepAlive: false,
        title: '领取心愿'
      }
    },
    {
      path: '/wishListScreen',
      name: 'wishListScreen',
      component: () => import('../view/tinyWishScreen/indexScreen'),
      meta: {
        keepAlive: false,
        title: '微心愿'
      }
    },
    {
      path: '/postMyWishScreen',
      name: 'postMyWishScreen',
      component: () => import('../view/tinyWishScreen/postMyWishScreen'),
      meta: {
        keepAlive: false,
        title: '许愿'
      }
    },
    {
      path: '/hGDS',
      name: 'hadGetDetailScreen',
      component: () => import('../view/tinyWishScreen/hadGetDetailScreen'),
      meta: {
        keepAlive: false,
        title: '心愿详情'
      }
    },
    {
      path: '/hGDMS',
      name: 'hadGetDetailMsgScreen',
      component: () => import('../view/tinyWishScreen/hadGetDetailMsgScreen'),
      meta: {
        keepAlive: false,
        title: '心愿详情'
      }
    },
    {
      path: '/hGDMPS',
      name: 'hadGetDetailPostMsgPost',
      component: () => import('../view/tinyWishScreen/hadGetDetailPostMsgPost'),
      meta: {
        keepAlive: false,
        title: '心愿详情'
      }
    },
    {
      path: '/gridMap',
      name: 'gridMap',
      component: () => import('../view/gridMap/gridMap'),
      meta: {
        keepAlive: false,
        title: '党建地图'
      }
    },
  ]
});
router.afterEach(route => {
  // 从路由的元信息中获取 title 属性
  if (route.meta.title) {
    document.title = route.meta.title;
    dd.biz.navigation.setTitle({
      title: route.meta.title, //控制标题文本，空字符串表示显示默认文本
      onSuccess: function (result) {
        /*结构
        {
        }*/
      },
      onFail: function (err) {}
    });
    // 如果是 iOS 设备，则使用如下 hack 的写法实现页面标题的更新
    if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      const hackIframe = document.createElement('iframe');
      hackIframe.style.display = 'none';
      hackIframe.src = '/static/html/fixIosTitle.html?r=' + Math.random();
      document.body.appendChild(hackIframe);
      setTimeout(_ => {
        document.body.removeChild(hackIframe)
      }, 300)
    }
  }
});
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});
export default router