import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const state = {
    versions:'0',
}
const mutations = {
    // SET_VERSIONS (state,versions) {
    //     state.versions = versions;
    //     window.sessionStorage.setItem('versions',  versions);
    // },
}
var actions = {
    // SET_VERSIONS: ({ commit },user) => {
    //     commit('SET_VERSIONS',user);
    // },
}
var getters = {
    // versions: state => window.sessionStorage.getItem('versions'),
}

export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters
})
