
// 引入axios
import axios from 'axios'


// 设置请求头可携带cookie
// axios.defaults.withCredentials = true;
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// axios.defaults.headers['Accept'] = 'application/json;charset=UTF-8';
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers['X-Access-Token'] = JSON.parse(localStorage.getItem('X-Access-Token'));

// 测试服务
// var base = 'http://'; 
// 生产地址
// var base = 'https://wx.jiedadt.com';

// export const baseUrl = "https://wx.jiedadt.com";
// export const base = "https://wx.meichengzhen.cn";
export const myBase2 = "http://47.98.213.13:30088/m-boot"


// 登录接口
export const login = (params) => {
  return axios.post(`/serv/oauth/token${params.param}`, params).then(res => res);
};

// 短信登录接口
export const loginMessage = (params) => {
  return axios.post(`${myBase2}/enterprise/login`, params.param).then(res => res);
};
// 小镇登录
export const loginTown = (params) => {
  return axios.post(`${myBase2}/enterprise/town/login`, params.param).then(res => res);
};
// 企业登录
export const loginEnterprise = (params) => {
  return axios.post(`${myBase2}/enterprise/enterprise/login`, params.param).then(res => res);
};
// 发送短信验证码
export const sendMessage = (params) => {
  return axios.get(`${myBase2}/enterprise/code`, { params: params }).then(res => res);
};
