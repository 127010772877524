import Vue from 'vue'
import App from './App.vue'
import 'amfe-flexible/index.js'
import store from './store'
import api from './api/index'
import router from './router'
import './assets/stylesheet/reset.css'
// import '@/utils/rem.js'
// 高德
import VueAMap from 'vue-amap';
// 引入qs
import Qs from 'qs';
import { Button, Popup, Picker, Field, Cell, CellGroup, RadioGroup, Radio, Checkbox, CheckboxGroup, DatetimePicker, Uploader, Area, Circle, Tag, Swipe, SwipeItem, List, PullRefresh } from 'vant';
import axios from 'axios'
import echarts from 'echarts'
// 引入钉钉
import * as dd from 'dingtalk-jsapi';
// 引入图片压缩
import lrz from 'lrz'

//引入需要的vant组件
import { NavBar, Tab, Tabs, Card, Icon, Toast, Image as VanImage, Overlay, Loading, Progress } from 'vant'
// import VConsole from 'vconsole';
// const vConsole = new VConsole();

Vue.use(Tab)
  .use(Tabs)
  .use(NavBar)
  .use(Card)
  .use(Icon)
  .use(Toast)
  .use(VanImage)
  .use(Overlay)
  .use(Loading)
  .use(Progress)

Vue.prototype.$echarts = echarts
Vue.use(Circle);
Vue.use(VueAMap);
Vue.use(Button).use(Popup).use(Picker).use(Field).use(Cell).use(CellGroup).use(RadioGroup).use(Radio).use(Checkbox).use(CheckboxGroup).use(DatetimePicker).use(Uploader).use(Area).use(Tag).use(Swipe).use(SwipeItem).use(List).use(PullRefresh);
Vue.prototype.$ajax = axios;
Vue.config.productionTip = false;
Vue.prototype.$qs = Qs;
// api共用接口js
Vue.prototype.$api = api;
Vue.prototype.$dd = dd;

// VueAMap.initAMapApiLoader({
//   key: '09ba3397bed53916f7419b24ae4a9707',
//   plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geolocation'],
//   uiVersion: '1.0.11', // 版本号
//   v: '1.4.4'
// });


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
